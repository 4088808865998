import actions from './actions'

const initState = {
  idToken: null,
  loginError: null
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        loginError: null
      }
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        idToken: action.token
      }
    case actions.LOGOUT:
      return initState
    case actions.LOGIN_ERROR:
      return { ...state, loginError: action.error }
    case actions.GET_USER_INFO:
      return { ...state, loggedUser: action.payload }
    default:
      return state
  }
}
