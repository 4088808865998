import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'

// Action Names
export const FETCH_CALL_STATUS = 'FETCH_CALL_STATUS'
export const GET_CALL_DURATION = 'GET_CALL_DURATION'
export const CLEAR_CALL_DURATION = 'CLEAR_CALL_DURATION'

// API URLs
const { XONE_CASH_API } = CONSTANTS

export const fetchCallStatus = (token, companyId = null, searchItem = null) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/calls/callStatus/findAll`
  if (!!searchItem) {
    url += `?name=${searchItem}`
    if (!!companyId) {
      url += `&&callCompany=${companyId}`
    }
  } else if (!!companyId) {
    url += `?companyId=${companyId}`
  }
  const { data } = await axios.get(
    url, { headers: { 'Authorization': 'Bearer ' + token } }
  )
  dispatch({ type: FETCH_CALL_STATUS, payload: data.data })
}

export const resetCallStatus = (filters) => async (dispatch) => {
  dispatch({ type: FETCH_CALL_STATUS, payload: filters })
}

export const saveCall = (call, token) => async (dispatch) => {
  try {
    await axios.post(
      `${XONE_CASH_API}/v1/calls/create`, call,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    dispatch({ type: CLEAR_CALL_DURATION, payload: null })
    message.success('La llamada ha sido guardada exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar la llamada')
  }
}

export const saveCallStatus = (itemData, token) => async (dispatch) => {
  let url = `${XONE_CASH_API}/v1/calls/callStatus/`
  let item = {
    name: itemData.name,
    description: itemData.description,
    type: itemData.type,
    callCompany: itemData.companyId
  }
  try {
    let { data } = {} // eslint-disable-line
    if (itemData.statusId === undefined) {
      url += 'create'
      data = await axios.post(url, item, { headers: { 'Authorization': 'Bearer ' + token } })
    } else {
      url += `update/${itemData.statusId}`
      data = await axios.put(url, item, { headers: { 'Authorization': 'Bearer ' + token } })
    }
    dispatch(fetchCallStatus(token, itemData.companyId))
    message.success('El substatus ha sido guardado exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al guardar el substatus')
  }
}

export const deleteStatus = (token, statusId = null, companyId) => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${XONE_CASH_API}/v1/calls/callStatus/delete/${statusId}`,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    dispatch(fetchCallStatus(token, companyId))
    message.success('El substatus se ha eliminado exitosamente')
  } catch (error) {
    message.error('Ha ocurrido un error al eliminar el substatus')
  }
}

export const getCallDuration = (callId) => async (dispatch) => {
  const xmlData = `<Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">
      <Body>
        <GetDurationTime xmlns="http://tempuri.org/">
          <id>${callId}</id>
        </GetDurationTime>
      </Body>
    </Envelope>`
  try {
    const { data } = await axios({
      method: 'post',
      url: 'http://190.11.253.162:8089/WSAGYPRO/WebServiceAGY.asmx',
      data: xmlData,
      headers: { 'Content-Type': 'text/xml' }
    })
    const time = (!!data && data.indexOf('<GetDurationTimeResult>') !== -1) ? data.split('<GetDurationTimeResult>')[1].split('<')[0] : null
    dispatch({ type: GET_CALL_DURATION, payload: time })
  } catch (error) {
    message.error('Ocurrió un problema al obtener la información de la llamada')
  }
}
