import React from "react";
import { Link } from "react-router-dom";
import imgLogo from "../../../image/logo.png";
import icon from "../../../image/logo.png";

export default ({ collapsed, userLevel }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link
              to={userLevel === 1 ? "/app/csv-upload" : "/app/management-trays"}
            >
              <img className="isoLogoWrapperImg" src={icon} alt="Logo"/>
            </Link>
          </h3>
        </div>
      ) : (
          <h3>
            <Link
              to={userLevel === 1 ? "/app/csv-upload" : "/app/management-trays"}
            >
              <img className="isoLogoWrapperImg" src={imgLogo}  alt="Logo"/>
            </Link>
          </h3>
        )}
    </div>
  );
};
