import Auth from './auth/reducer'
import App from './app/reducer'
import tickets from './tickets/reducers'
import collection from './collection/reducers'
import agents from './agents/reducers'
import companies from './companies/reducers'
import contacts from './contacts/reducers'
import calls from './calls/reducers'
import csvUpload from './csvUpload/reducers'
import sms from './sms/reducers'

export default {
  Auth,
  App,
  tickets,
  collection,
  agents,
  companies,
  contacts,
  calls,
  sms,
  csvUpload,
}
