import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'

const routes = [
  {
    path: 'dashboard',
    component: asyncComponent(() => import('../../pages/Dashboard')),
  },
  {
    path: 'management-trays',
    component: asyncComponent(() => import('../../pages/ManagementTrays')),
  },
  {
    path: 'management-details/:id',
    component: asyncComponent(() => import('../../pages/ManagementDetails')),
  },
  {
    path: 'collections-portfolio',
    component: asyncComponent(() => import('../../pages/CollectionsPortfolio')),
  },
  {
    path: 'users-list',
    component: asyncComponent(() => import('../../pages/UsersList')),
  },
  {
    path: 'user-profile/:id',
    component: asyncComponent(() => import('../../pages/UserProfile')),
  },
  {
    path: 'calls-catalog',
    component: asyncComponent(() => import('../../pages/CallsCatalog')),
  },
  {
    path: 'companies-catalog',
    component: asyncComponent(() => import('../../pages/CompaniesCatalog')),
  },
  {
    path: 'monthly-goals',
    component: asyncComponent(() => import('../../pages/MonthlyGoals')),
  },
  {
    path: 'payments-delay-catalog',
    component: asyncComponent(() => import('../../pages/PaymentsDelayCatalog')),
  },
  {
    path: 'postponed-payment-catalog',
    component: asyncComponent(() => import('../../pages/PostponedPaymentCatalog')),
  },
  {
    path: 'agents-monitor',
    component: asyncComponent(() => import('../../pages/AgentsMonitor')),
  },
  {
    path: 'collection-report',
    component: asyncComponent(() => import('../../pages/CollectionReport')),
  },
  {
    path: 'collection-management-report',
    component: asyncComponent(() => import('../../pages/CollectionManagementReport')),
  },
  {
    path: 'budget-report',
    component: asyncComponent(() => import('../../pages/BudgetReport')),
  },
  {
    path: 'csv-upload',
    component: asyncComponent(() => import('../../pages/CsvUpload')),
  },
]

class AppRouter extends Component {
  render () {
    const { url, style } = this.props
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute
          return (
            <Route
              exact={exact !== false}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          )
        })}
      </div>
    )
  }
}

export default AppRouter
