import {
    FETCH_COMPANIES_BY_AGENT,
    FETCH_COMPANIES_OBJECT_BY_AGENT,
    FETCH_AGENTS,
    FETCH_AGENTS_BY_COMPANY,
    GET_ALL_AGENTS,
    GET_ROLES,
    GET_GROUPS
} from './actions'

const initialState = {
    companies: null,
    companiesObj: null,
    all: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES_BY_AGENT:
            return {...state, companies: action.payload}
        case FETCH_COMPANIES_OBJECT_BY_AGENT:
            return {...state, companiesObj: action.payload}
        case FETCH_AGENTS:
            return {...state, all: action.payload}
        case FETCH_AGENTS_BY_COMPANY:
            return {...state, agentsByCompany: action.payload}
        case GET_ALL_AGENTS:
            return {...state, agentsList: action.payload}
        case GET_ROLES:
            return {...state, agentRoles: action.payload}
        case GET_GROUPS:
            return {...state, agentGroups: action.payload}
        default:
            return state
    }
}
