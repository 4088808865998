import {
  FETCH_MONTHLY_GOALS,
  GET_INVOICES_BY_CUSTOMER,
  GET_INSTALLMENTS_BY_CREDIT,
  GET_PAYMENTS_BY_CUSTOMER,
  GET_INVOICE_DETAILS_BY_CREDIT,
  GET_CLIENT_INFO,
  GET_ALL_COLLECTIONS,
  GET_COMPANIES_BY_AGENT,
  GET_ALL_GOALS,
  GET_CLIENT_ACCOUNT_STATUS,
  COLLECTIONS_REPORT,
  FETCH_ACCOUNT_EXECUTIVES,
  BUDGET_REPORT,
  GENERATE_TICKETS, GET_ALL_CUSTOMER_PAYMENT_PREDICTIONS,
  LOADER_COLLECTION, LOADER_COLLECTION_REPORT,
} from './actions'

const initialState = {
  monthlyGoals: null,
  invoicesByCustomer: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_GOALS:
      return { ...state, monthlyGoals: action.payload }
    case GET_INVOICES_BY_CUSTOMER:
      return { ...state, invoicesByCustomer: action.payload }
    case GET_INSTALLMENTS_BY_CREDIT:
      return { ...state, installmentsByCredit: action.payload }
    case GET_PAYMENTS_BY_CUSTOMER:
      return { ...state, paymentsByCustomer: action.payload }
    case GET_INVOICE_DETAILS_BY_CREDIT:
      return { ...state, invoiceDetails: action.payload }
    case GET_CLIENT_INFO:
      return { ...state, clientData: action.payload }
    case GET_ALL_COLLECTIONS:
      return { ...state, installmentsList: action.payload }
    case GET_ALL_CUSTOMER_PAYMENT_PREDICTIONS:
      return { ...state, predictionsList: action.payload }
    case GET_COMPANIES_BY_AGENT:
      return { ...state, companiesByAgent: action.payload }
    case GET_ALL_GOALS:
      return { ...state, goalsList: action.payload }
    case GET_CLIENT_ACCOUNT_STATUS:
      return { ...state, clientAccountStatus: action.payload }
    case FETCH_ACCOUNT_EXECUTIVES:
      return { ...state, accountExecutivesList: action.payload }
    case COLLECTIONS_REPORT:
      return { ...state, collectionsReport: action.payload }
    case BUDGET_REPORT:
      return { ...state, budgetReport: action.payload }
    case GENERATE_TICKETS:
      return { ...state, ticketsGenerated: action.payload }
    case LOADER_COLLECTION:
      return { ...state, loaderCollection: action.payload }
    case LOADER_COLLECTION_REPORT:
      return { ...state, loaderCollectionReport: action.payload }
    default:
      return state
  }
}
