const options = [
  {
    key: 'cartera',
    label: 'Cobranzas',
    leftIcon: 'ion-arrow-graph-up-right',
    userLevel: 1,
    children: [
      {
        key: 'management-trays',
        label: 'Bandejas de Gestión',
        userLevel: 2
      },
      {
        key: 'collections-portfolio',
        label: 'Cartera de cobranzas',
        userLevel: 3
      },
      {
        key: 'monthly-goals',
        label: 'Datos de recaudación',
        userLevel: 3
      },
      {
        key: 'csv-upload',
        label: 'Importar CSV',
        userLevel: 1
      }
    ]
  },
  {
    key: 'agentes',
    label: 'Agentes',
    leftIcon: 'ion-android-person-add',
    userLevel: 3,
    children: [
      {
        key: 'users-list',
        label: 'Usuarios (Agentes)',
        userLevel: 3
      },
      {
        key: 'agents-monitor',
        label: 'Monitoreo de Agentes',
        userLevel: 3
      }
    ]
  },
  {
    key: 'catalogos',
    label: 'Catálogos',
    leftIcon: 'ion-document-text',
    userLevel: 3,
    children: [
      {
        key: 'calls-catalog',
        label: 'Sub-estados de llamadas',
        userLevel: 3
      },
      {
        key: 'payments-delay-catalog',
        label: 'Retrasos de pago',
        userLevel: 3
      },
      {
        key: 'postponed-payment-catalog',
        label: 'Pago postergado',
        userLevel: 3
      },
      {
        key: 'companies-catalog',
        label: 'Lista de empresas',
        userLevel: 3
      }
    ]
  },
  {
    key: 'reports',
    label: 'Reportes',
    leftIcon: 'ion-clipboard',
    userLevel: 3,
    children: [
      {
        key: 'collection-management-report',
        label: 'Gestión de Cobranza',
        userLevel: 3
      },
      {
        key: 'budget-report',
        label: 'Budget',
        userLevel: 3
      },
      {
        key: 'collection-report',
        label: 'Cobranza',
        userLevel: 3
      }
    ]
  }
]

export default options
