import {
  FETCH_CALL_STATUS, GET_CALL_DURATION, CLEAR_CALL_DURATION
} from './actions'

const initialState = {
  statusList: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALL_STATUS:
      return { ...state, statusList: action.payload }
    case GET_CALL_DURATION:
      return { ...state, callDuration: action.payload }
    case CLEAR_CALL_DURATION:
      return { ...state, callDuration: null }
    default:
      return state
  }
}
