import axios from 'axios'
import CONSTANTS from '../../settings/constants'
import message from './../../components/common/feedback/message'
import * as moment from 'moment'

// Action Names
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES'
export const SEND_BULK_SMS = 'SEND_BULK_SMS'

// API URLs
const { XONE_CASH_API } = CONSTANTS


export const getTemplates = (token) => async (dispatch) => {
  const { data } = await axios.get(
    `${XONE_CASH_API}/v1/sms/smsTemplate/getAll`,
    { headers: { 'Authorization': 'Bearer ' + token } }
  )

  dispatch({ type: GET_ALL_TEMPLATES, payload: data })
}

export const sendBulkSms = (token, formData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${XONE_CASH_API}/v1/sms/sendBulk`,
      { ...formData },
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
    dispatch({ type: SEND_BULK_SMS, payload: data })
    message.success('Los mensajes se han enviado correctamente');
  } catch (error) {
    message.error('Ha ocurrido un error al enviar los msj')
  }
}